import React from "react"
import SEO from "../components/SEO"
import Layout from '../components/Layout'
import styles from "../css/service.module.css"
import stylesSpoth from "../css/spotlight.module.css"
import StyledHero from "../components/StyleHero"
import Title from "../components/Title"
import { graphql } from "gatsby"
/*import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styles from "../css/single-blog.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { log } from "util"
import SEO from "../components/SEO"*/

const Solution = ({data}) => {
  const {titleTab, placeholderHeader, smallText } = data.solution
  const info = data.solution.placeholderSubCopy.childContentfulRichText.html

//<img src={img} className={stylesSpoth.moduloGifImg} alt=""/>
  return (
    <div>
            <Layout>
            <SEO titlePage={titleTab} />
            <section className={styles.services}>
            <StyledHero home="true"  >
              <div className={stylesSpoth.banner}>
                <div>
                  <p>{smallText}</p>
                  <h1>{placeholderHeader}</h1>
                  <div dangerouslySetInnerHTML={{ __html: info }} />
                </div>
                <div>
                  
                </div>
              </div>
            </StyledHero>
            </section>
            </Layout>
            
        </div>
  )
  /*const {
    title,
    published,
    text: { json },
  } = data.post
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        return (
          <div className="rich">
            <h3>this is awesome image</h3>
            <img width="400" src={node.data.target.fields.file["en-US"].url} />
            <p>images provided by john doe</p>
          </div>
        )
      },
      "embedded-entry-block": node => {
        const { title, image, text } = node.data.target.fields
        console.log(text)

        return (
          <div>
            <br />
            <br />
            <br />
            <br />
            <h1>this is other post : {title["en-US"]}</h1>
            <img
              width="400"
              src={image["en-US"].fields.file["en-US"].url}
              alt=""
            />
            {documentToReactComponents(text["en-US"])}
            <br />
            <br />
            <br />
            <br />
          </div>
        )
      },
    },
  }
  return (
    <Layout>
      <SEO title={title} />
      <section className={stylesSpoth.blog}>
        <div className={styles.center}>
          <h1>{title}</h1>
          <h4>published at : {published}</h4>
          <article className={styles.post}>
            {documentToReactComponents(json, options)}
          </article>
          <AniLink fade to="/blog" className="btn-primary">
            all posts
          </AniLink>
        </div>
      </section>
    </Layout>
  )*/
}
export const query = graphql`
query($slug: String!){
  solution:contentfulSolutions(slug:{eq:$slug}){
    titleTab
    placeholderHeader
    placeholderSubCopy{
      childContentfulRichText{
        html
      }
    }
    smallText
  }
}
`
export default Solution
